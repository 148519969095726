// NOTE: semibold is intentionally mapped to bold because bold is too heavy

// normal styles
@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-Regular.otf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-SemiBold.otf');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-SemiBold.otf');
	// src: url('../assets/fonts/LinearSans-Bold.otf');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-Bold.otf');
	// src: url('../assets/fonts/LinearSans-Black.otf');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-Black.otf');
	// src: url('../assets/fonts/LinearSans-Heavy.otf');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

// italic styles
@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-Italic.otf');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-SemiBoldItalic.otf');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-SemiBoldItalic.otf');
	// src: url('../assets/fonts/LinearSans-BoldItalic.otf');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-BoldItalic.otf');
	// src: url('../assets/fonts/LinearSans-BlackItalic.otf');
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Linear Sans';
	src: url('../assets/fonts/LinearSans-BlackItalic.otf');
	// src: url('../assets/fonts/LinearSans-HeavyItalic.otf');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

// dashboard digits font
@font-face {
	font-family: 'Baloo Chettan 2';
	src: url('../assets/fonts/BalooChettan2-VariableFont_wght.ttf');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
