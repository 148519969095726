// Fonts
@import 'fonts';

// Custom overrides
@import 'bootstrap';

// Vendors
@import 'bootstrap/scss/bootstrap';
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-toastr/toastr';
@import "ngx-bootstrap/datepicker/bs-datepicker.css";

// Custom
@import 'main.scss';
@import 'styles/owl-datetime-picker.scss';
@import 'flag-icon-css/css/flag-icons.min.css';
