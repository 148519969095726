@import 'bootstrap';

body {
	min-height: 100%;
	overflow-y: scroll;
}

main {
	$user-menu-height: 80px;
	$footer-height: 72px;

	min-height: calc(100vh - #{$user-menu-height} - #{$footer-height});
}

.coming-soon-container {
	position: relative;
	display: inline-block;

	img {
		display: block;
		width: 100%;
		min-height: 80vh;
		height: auto;
		filter: grayscale(100%);
	}

	.coming-soon {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-30deg);
		background-color: #adadad;
		color: #333;
		white-space: nowrap;
		text-transform: uppercase;
		z-index: 9999;
		// border-radius: 10px;
		// box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
	}
}

// class to remove 'x' from the end of search inputs
input[type="search"].no-clear::-webkit-search-decoration,
input[type="search"].no-clear::-webkit-search-cancel-button,
input[type="search"].no-clear::-webkit-search-results-button,
input[type="search"].no-clear::-webkit-search-results-decoration {
	display: none;
}

.text-wrapped-spaced {
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-all; // make sure long words are broken (like URLs)
}

.avatar-circle {
	border-radius: 50%;
	height: 36px;
	width: 36px;
}

.scaled-image {
	object-fit: cover;
}

// icon before/after a field value: [icon] (503) 555-5555 or (503) 555-5555 [icon]
.field-icon {
	width: 24px;
	height: auto;
}

.field-required {
	label::after {
		content: "*";
		color: $form-label-color;
		margin-left: 5px;
	}
}

// bootstrap extensions
.stretchable-container {
	// make container be compatible with Bootstrap's .stretched-link class
	// most of the <table> elements are not compatible, adding this to <tr> makes them compatible
	// https://stackoverflow.com/a/57591579/634906
	transform: rotate(0);
}

// this class allows links behind the stretched link to be clickable
// also use appStopPropagation directive to prevent click event from bubbling up
.inner-stretched-link {
	position: relative;
	z-index: $stretched-link-z-index+1;
}

.placeholder-xl {
	min-height: 1.8em;
}

.tiny {
	font-size: .675em;
}

.bg-light-hover:hover {
	background-color: $light !important;
}

// TODO: FIGURE OUT WHY LABELS ROTATED 45* EVEN WHEN ENOUGH SPACE
.ngx-charts .textDataLabel {
	transform: rotate(0deg) !important;
	text-anchor: middle !important;
	alignment-baseline: middle !important;
}

// TODO: REMOVE ONCE UPGRADED TO LATEST BOOTSTRAP
$primary-bg-subtle: tint-color($primary, 80%) !default;
$secondary-bg-subtle: tint-color($secondary, 80%) !default;
$success-bg-subtle: tint-color($success, 80%) !default;
$info-bg-subtle: tint-color($info, 80%) !default;
$warning-bg-subtle: tint-color($warning, 80%) !default;
$danger-bg-subtle: tint-color($danger, 80%) !default;
$light-bg-subtle: mix($gray-100, $white) !default;
$dark-bg-subtle: $gray-400 !default;

.bg-primary-subtle {
	background-color: $primary-bg-subtle !important;
}

.bg-secondary-subtle {
	background-color: $secondary-bg-subtle !important;
}

.bg-success-subtle {
	background-color: $success-bg-subtle !important;
}

.bg-info-subtle {
	background-color: $info-bg-subtle !important;
}

.bg-warning-subtle {
	background-color: $warning-bg-subtle !important;
}

.bg-danger-subtle {
	background-color: $danger-bg-subtle !important;
}

.bg-light-subtle {
	background-color: $light-bg-subtle !important;
}

.bg-dark-subtle {
	background-color: $dark-bg-subtle !important;
}

.mt-n1 {
	margin-top: -0.25rem !important;
}

.mt-n2 {
	margin-top: -0.5rem !important;
}

.mb-n1 {
	margin-bottom: -0.25rem !important;
}

.mx-n1 {
	margin-left: -0.25rem !important;
	margin-right: -0.25rem !important;
}

.mx-n2 {
	margin-left: -0.5rem !important;
	margin-right: -0.5rem !important;
}

.mx-n3 {
	margin-left: -1rem !important;
	margin-right: -1rem !important;
}

.mx-n4 {
	margin-left: -1.5rem !important;
	margin-right: -1.5rem !important;
}

// input placeholder font weight
input::placeholder {
	font-weight: normal;
}

// input spinner
input.form-control.loading,
ng-select.form-select.loading {
	background-color: #ffffff;
	background-image: url("https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif");
	background-position: right $input-padding-x center;
	background-repeat: no-repeat;
	background-size: calc($input-height * 0.6);

	&.form-control-sm {
		background-position: right $input-padding-x-sm center;
		background-size: calc($input-height-sm * 0.6);
	}

	&.form-control-lg {
		background-position: right $input-padding-x-lg center;
		background-size: calc($input-height-lg * 0.6);
	}
}

// search input icon
input.form-control.form-control-search[type="search"] {
	text-indent: $input-padding-x * 2.1;
	background-position-x: $input-padding-x;
	background-size: calc($input-height * 0.4);

	&.form-control-sm {
		text-indent: $input-padding-x-sm * 2.4;
		background-position-x: $input-padding-x-sm;
		background-size: calc($input-height-sm * 0.4);
	}

	&.form-control-lg {
		text-indent: $input-padding-x-lg * 1.8;
		background-position-x: $input-padding-x-lg;
		background-size: calc($input-height-lg * 0.4);
	}

	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23BDC4CD' d='M504.1 471l-134-134C399.1 301.5 415.1 256.8 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c48.79 0 93.55-16.91 129-45.04l134 134C475.7 509.7 481.9 512 488 512s12.28-2.344 16.97-7.031C514.3 495.6 514.3 480.4 504.1 471zM48 208c0-88.22 71.78-160 160-160s160 71.78 160 160s-71.78 160-160 160S48 296.2 48 208z'/%3E%3C/svg%3E");
	background-position-y: 50%;
	background-repeat: no-repeat;
}

// bootstrap overrides
.dropdown-menu.dropdown-overflow {
	max-height: 380px;
	overflow-y: auto;
}

// ng-select overrides
.ng-select.custom-ng-select {
	min-height: 0;
	padding-left: $input-padding-x;
	padding-right: $input-padding-x;

	.ng-dropdown-panel {
		width: auto !important;
		min-width: 100%;
	}

	.ng-select-container {
		min-height: 0;
		border: none;

		.ng-value-container {
			padding-left: 0;

			.ng-value {
				.ng-value-label {
					// color: $
				}
			}

			.ng-input {
				padding-left: 0;
			}
		}
	}

	&[disabled],
	&[readonly],
	&.ng-select-disabled {
		.ng-select-container {

			.ng-input,
			.ng-value .ng-value-label {
				color: $input-disabled-color;
				background-color: $input-disabled-bg;
			}
		}
	}
}

.form-check-muted {
	.form-check-input {
		&:checked {
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}

@each $color,
$value in $grays {
	.gray-#{$color} {
		color: $value !important;
	}
}

@each $color,
$value in $grays {
	.bg-gray-#{$color} {
		background-color: $value !important;
	}
}

form {
	.is-invalid {
		label {
			color: $form-feedback-invalid-color;
		}

		input,
		select,
		textarea {
			@extend .is-invalid;
		}
	}
}

label {
	.text-muted {
		color: $gray-500 !important;
	}
}

.input-group {
	&:not(.input-group-lg) {
		.ng-select.custom {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.ng-select.custom {
		padding-left: 0;
		padding-right: $input-padding-x;
	}

	&>.btn-outline-secondary {
		&:not(:first-child) {
			border-color: $input-border-color;
		}
	}
}

.table>thead.table-white>tr>th,
.table>tfoot.table-white>tr>td {
	background-color: $white;
}

// TODO: REVIEW THE NEED FOR THIS
.table {
	thead {
		color: $table-caption-color;

		tr {
			vertical-align: middle;
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					border-bottom-width: 0px;
				}
			}
		}
	}

	&.table-hover {
		tbody {
			tr {
				&.table-row-clickable {
					cursor: pointer !important;

					&:hover {
						.btn-icon-only {
							color: $gray-800 !important;
						}
					}
				}
			}
		}
	}
}

.nav-tabs {
	.nav-link {
		margin-right: 1rem;
		margin-left: 1rem;

		&:first-child {
			margin-left: 0rem;
		}

		&:last-child {
			margin-right: 0rem;
		}

		&.active {
			border-bottom-width: $nav-tabs-border-width * 2;
		}
	}
}

.custom-accordion {
	.panel {
		.panel-default {
			border: none;

			.panel-heading {
				background-color: transparent;
				border-bottom: none;
				padding: 0px;

				.panel-title {
					.accordion-toggle {
						.btn-link {
							background-color: $gray-200;
							color: $body-color;
							text-decoration: none;
							text-align: left;
							font-size: $font-size-sm;
							position: relative;
							display: block;
							width: 100%;
							padding: 0.5rem 0.75rem;

							&:after {
								content: '';
								background-repeat: no-repeat;
								background-position: center center;
								position: absolute;
								top: 50%;
								right: 0.75rem;
								width: 20px;
								height: 20px;
								transform: translateY(-50%);
							}
						}

						&[aria-expanded="true"] {
							.btn-link {
								&:after {
									background-image: url(escape-svg('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{$gray-700}" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>'));
								}
							}
						}

						&[aria-expanded="false"] {
							.btn-link {
								&:after {
									background-image: url(escape-svg('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{$gray-700}" d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/></svg>'));
								}
							}
						}
					}
				}
			}

			.panel-collapse {
				.panel-body {
					padding-top: $spacer * 0.5;
					padding-right: 0px;
					padding-bottom: 0px;
					padding-left: 0px;
				}
			}
		}
	}
}

// other utilities and helpers
.c-pointer {
	cursor: pointer !important;
}

.field-label {
	font-size: $form-label-font-size;
	font-style: $form-label-font-style;
	font-weight: $form-label-font-weight;
	color: $form-label-color
}

.field-value {
	
}

.value-empty {
	font-style: italic !important;
	color: #bbb !important;
}

.inline-edit-value {
	font-size: 1em;
	font-weight: normal;
	text-decoration: underline #bbb dotted !important;
	display: flex;
	justify-content: flex-start;

	&.inline-edit-value-empty {
		font-style: italic;
		color: #bbb;
	}

	&.btn {
		padding: 0px;
		color: $secondary;

		&[disabled] {
			text-decoration: none !important;
		}
	}
}

.btn-icon {
	text-align: center;
	width: 2rem;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.form-switch {
	display: flex;
	align-items: center;

	.form-check-input {
		padding-left: 2.5rem;
		height: 1.5rem;
		margin-right: $spacer * 0.5;
	}
}

.form-switch-sm {
	.form-check-input {
		padding-left: 2rem;
		height: 1rem;
	}
}

.btn-icon-only {
	@extend .btn-link;
	@extend .text-body;
	@extend .text-decoration-none;
	@extend .py-0;
	color: $gray-700 !important;
}

.btn-inline-edit {
	@include button-variant(transparent, // background
		transparent, // border
		$gray-300, // color
		transparent, // hover-background
		transparent, // hover-border
		$gray-300, // hover-color
		transparent, // active-background
		transparent, // active-border
		$gray-300 // active-color
	);
	padding: 0px !important;
	margin-left: $spacer * 0.25;
}

.radius-start-0 {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.radius-end-0 {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.radius-x-0 {
	border-radius: 0;
}

.bg-empty {
	background: $gray-200;
}

.toast-container {
	&.top-center {
		top: 0px;
		left: 50%;
		transform: translateX(-50%);

		.ngx-toastr {
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}
	}

	.ngx-toastr {
		background-position: 1.5rem center;
		color: $body-color;
		width: $modal-md;
		padding: 1.5rem 2rem;

		.toast-title,
		.toast-message {
			margin-left: 2.5rem;
		}

		.toast-close-button {
			@extend .btn-close;
			text-shadow: none;
			width: 0.8em;
			height: 0.8em;
			padding: 0px;

			span {
				display: none;
			}
		}

		&.toast-success {
			background-color: $success !important;
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='%23fff' d='M440.1 103C450.3 112.4 450.3 127.6 440.1 136.1L176.1 400.1C167.6 410.3 152.4 410.3 143 400.1L7.029 264.1C-2.343 255.6-2.343 240.4 7.029 231C16.4 221.7 31.6 221.7 40.97 231L160 350.1L407 103C416.4 93.66 431.6 93.66 440.1 103V103z'/></svg>");
			color: $white;

			.toast-close-button {
				background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
			}
		}

		&.toast-error {
			background-color: $danger !important;
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%23fff' d='M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 304c13.25 0 24-10.75 24-24v-128C280 138.8 269.3 128 256 128S232 138.8 232 152v128C232 293.3 242.8 304 256 304zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44C224.6 385.9 238.6 400 256 400s31.44-14.08 31.44-31.44C287.4 351.2 273.4 337.1 256 337.1z'/></svg>");
			color: $white;

			.toast-close-button {
				background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
			}
		}

		&.toast-info {
			background-color: $info !important;
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z'/></svg>");
		}

		&.toast-warning {
			background-color: $warning !important;
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M506.3 417l-213.3-364C284.8 39 270.4 32 256 32C241.6 32 227.2 39 218.1 53l-213.2 364C-10.59 444.9 9.851 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM52.58 432L255.1 84.8L459.4 432H52.58zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44c0 17.36 14.11 31.44 31.48 31.44s31.4-14.08 31.4-31.44C287.4 351.2 273.4 337.1 256 337.1zM232 184v96C232 293.3 242.8 304 256 304s24-10.75 24-24v-96C280 170.8 269.3 160 256 160S232 170.8 232 184z'/></svg>");
		}
	}
}

//
// Tabs
//

// .navigation {
// 	.nav-tabs {
// 		border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

// 		.nav-link {
// 			font-weight: 600;
// 			color: $secondary;
// 			padding-bottom: 1.25rem;
// 			margin-bottom: -$nav-tabs-border-width;
// 			background: none;
// 			border: $nav-tabs-border-width solid transparent;


// 			&:hover,
// 			&:focus {
// 				//border-color: $nav-tabs-link-hover-border-color;
// 				border-bottom: 3px solid $primary;
// 				color: $primary;
// 				// Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
// 				isolation: isolate;
// 			}

// 			&.disabled {
// 				color: $nav-link-disabled-color;
// 				background-color: transparent;
// 				border: transparent;
// 			}
// 		}

// 		.nav-link.active,
// 		.nav-item.show .nav-link {
// 			color: $primary;
// 			background: none;
// 			border-bottom: 3px solid $primary;
// 		}
// 	}
// }

.ng-select-focused {
	border: 1px solid $primary;
	box-shadow: 0 0 0 4px rgba(15, 126, 159, 0.2);
}

.fw-semi-bold {
	font-weight: 600;
}

.bg-main {
	background-color: #fafafa !important;
}

.ng-select .ng-select-container {
	border: none !important;
}

.cursor-pointer {
	width: 42px;
	color: $gray-400
}

.custom-ng-select {

	&,
	&.ng-select,
	&.ng-select-single,
	&.ng-select-opened {
		@extend .form-control;
		@extend .p-0;

		.ng-select-container {
			background-color: $input-bg;
			border-radius: $border-radius;

			.ng-value-container {
				border-radius: $border-radius;
				padding-left: 0px;

				.ng-placeholder {
					border-radius: $border-radius;
					color: $input-placeholder-color;
				}

				.ng-placeholder,
				.ng-value,
				.ng-input {
					padding-left: $input-padding-x;
				}
			}
		}

		.ng-dropdown-panel {
			.ng-dropdown-panel-items {
				padding: $dropdown-padding-y $dropdown-padding-x !important;

				.ng-option {
					color: $dropdown-link-color !important;
					padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;

					&.ng-option-marked {
						background-color: $dropdown-link-hover-bg !important;
						color: $dropdown-link-hover-color !important;
					}

					&.ng-option-selected {
						background-color: $dropdown-link-active-bg !important;
						color: $dropdown-link-active-color !important;
					}
				}

				.ng-optgroup {
					font-size: 0.75rem !important;
					text-transform: uppercase;
					color: $dropdown-header-color !important;
					padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
					margin-top: $spacer * 0.5;

					&:first-child {
						margin-top: 0px;
					}
				}
			}
		}
	}

	&.ng-select-multiple {
		@extend .form-control;
		@extend .p-0;

		.ng-select-container {
			.ng-value-container {
				padding-left: $input-padding-x;

				.ng-placeholder,
				.ng-value,
				.ng-input {
					padding-left: 0px;
				}
			}
		}
	}
}

.input-group {
	.input-group-text {
		+.custom-ng-select {
			.ng-dropdown-panel {
				left: -42px;
				width: calc(100% + 42px) !important;
			}
		}
	}
}

.text-secondary-dark {
	color: $gray-400
}

// TODO: REMOVE ONCE FIXED - NGX-BOOTSTRAP BUG WORKAROUND
.popover {
	position: absolute;
	top: 0;
	left: 0 #{"/* rtl:ignore */"};
}

.popover-arrow {
	position: absolute;
}

.tooltip {
	position: absolute;
}

.tooltip-arrow {
	position: absolute;
}


.change-calculator {
	--bs-popover-max-width: 550px;
}

.bill-amount-container {
	--bs-popover-max-width: 400px;
}

// .option-price-container {
// 	--bs-popover-max-width: 300px;
// }

.bs-datepicker-container {
	padding: 0px;

	.bs-calendar-container {
		.bs-media-container {
			.bs-datepicker-head {
				background-color: $dropdown-bg;
				border-radius: $border-radius-lg $border-radius-lg 0px 0px;
				padding: 0.75rem 1rem 0.5rem;

				.previous,
				.current,
				.next {
					font-size: $font-size-base;
					line-height: $line-height-base;
					color: $body-color;

					&:hover {
						background-color: transparent;
					}
				}

				.current {
					padding: 0px;
				}
			}

			.bs-datepicker-body {
				border: none;
				padding: 0px;

				table {
					thead {
						tr {
							th {
								font-size: $font-size-sm;
								line-height: $line-height-sm;
								color: $gray-700;
								padding-top: 0.5rem;
								padding-bottom: 0.5rem;
							}
						}
					}

					tbody {
						tr {
							td {
								span {
									color: $body-color;

									&.is-other-month {
										color: rgba($gray-500, 0.5);
									}

									&.is-highlighted {

										&:not(&.disabled),
										&:not(&.selected) {
											background-color: $gray-200;
											color: $body-color;
										}
									}

									&.selected {
										background-color: $primary !important;
										color: $white !important;
									}
								}
							}

							&:first-child {
								td {
									border-top: $border-width $border-color $border-style;
									padding-top: 0.5rem;
								}
							}
						}
					}
				}
			}
		}
	}
}